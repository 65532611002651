import { createAction, props } from '@ngrx/store';

import { Aggregate, Claim } from '@wam/shared';

export const loadFeedAchievements = createAction('[Achievements Page] Load Feed');

export const loadFreshFeedAchievements = createAction('[Achievements Page] Load Fresh Feed');

export const loadFeedMessages = createAction('[Messages Component] Load Feed');

export const loadFreshFeedMessages = createAction('[Messages Component] Load Fresh Feed');

export const loadFeedUsage = createAction('[Usage Page] Load Feed');

export const loadFreshFeedUsage = createAction('[Usage Page] Load Fresh Feed');

export const loadFeedSuccess = createAction(
  '[Feed API] Load Feed Success',
  props<{ aggregates: Aggregate[] }>(),
);

export const loadFreshFeedSuccess = createAction(
  '[Feed API] Load Fresh Feed Success',
  props<{ aggregates: Aggregate[] }>(),
);

export const loadFeedFail = createAction('[Feed API] Load Feed Fail', props<{ error: string }>());

export const markAsRead = createAction(
  '[Messages Component] Mark as Read',
  props<{ item: Aggregate }>(),
);

export const markAsReadSuccess = createAction(
  '[Feed API] Mark as Read Success',
  props<{ item: Aggregate }>(),
);

export const markAsReadFailed = createAction(
  '[Feed API] Mark as Read Failed',
  props<{ item: Aggregate }>(),
);

export const endOfFeed = createAction('[Feed API] End Of Feed');

export const clearFeed = createAction('[Feed API] Clear Feed');

export const clearClaimFeed = createAction(
  '[Feed API] Clear Claim Feed',
  props<{ claim: Claim }>(),
);
