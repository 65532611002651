import { createReducer, on } from '@ngrx/store';
import * as claimsActions from './claims.actions';
import { isEqual, sortBy, uniqBy } from 'lodash-es';
import { ClaimsState } from '@wam/shared';
import { getFromLocalStorage as getClaimsFromLocalStorage } from '../claim.data';

const initialState: ClaimsState = {
  claims: getClaimsFromLocalStorage(),
  selectedClaim: getClaimsFromLocalStorage()[0],
  upstartSharedClaims: [],
  pending: false,
  loaded: false,
  error: null,
};

const claimsReducer = createReducer(
  initialState,
  on(claimsActions.loadClaimsParentHome, claimsActions.loadClaimsClaimsComponent, (state) => ({
    ...state,
    pending: true,
  })),
  on(claimsActions.loadClaimsSuccess, (state, { claims }) => ({
    ...state,
    pending: false,
    loaded: true,
    error: null,
    claims: isEqual(claims, state.claims) ? state.claims : claims,
    selectedClaim: isEqual(claims[0], state.selectedClaim) ? state.selectedClaim : claims[0],
  })),
  on(claimsActions.loadClaimsFail, (state, { error }) => ({
    ...state,
    pending: false,
    error: error,
    claims: [],
    selectedClaim: null,
  })),
  on(claimsActions.loadUpstartSharedClaimsSuccess, (state, { upstartSharedClaims }) => ({
    ...state,
    upstartSharedClaims,
  })),
  on(claimsActions.updateSelectedChild, (state, { claim }) => ({
    ...state,
    selectedClaim: claim,
  })),
  on(claimsActions.addChildSuccess, (state, { claim }) => {
    const newClaims = sortBy(uniqBy([...state.claims, claim], 'code'), ['resourceName']);
    return {
      ...state,
      claims: isEqual(newClaims, state.claims) ? state.claims : newClaims,
      selectedClaim: newClaims.length === 1 ? newClaims[0] : state.selectedClaim,
    };
  }),
  on(claimsActions.removeChild, (state, { claim }) => {
    const newClaims = state.claims.filter((current) => current !== claim);
    return {
      ...state,
      pending: true,
      loaded: true,
      claims: newClaims,
      selectedClaim: newClaims[0],
    };
  }),
  on(claimsActions.removeChildSuccess, (state, { claim }) => ({
    ...state,
    pending: false,
  })),
  on(claimsActions.removeChildFail, (state, { error, claim }) => {
    const newClaims = sortBy([...state.claims, claim], ['resourceName']);
    return {
      ...state,
      pending: false,
      error: error,
      claims: newClaims,
      selectedClaim: newClaims[0],
    };
  }),
);

export function reducer(state, action) {
  return claimsReducer(state, action);
}
